html, body, .container, #map
{
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.editor
{
  position: absolute;
  bottom: 56px;
  left: 8px;
  z-index: 9999;

  #select
  {
    width: 100%;
    font-size: 16px;
  }
}

.header
{
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 999;
  background-color: rgba( 0, 0, 0, 0.8);
  padding: 16px;
  color: #ffffff;
  max-width: 90%;

  h1
  {
    margin: 0;
    margin-bottom: 1rem;
    font-size: 1.2rem;
  }

  p
  {
    line-height: 1.5rem;
    margin: 0;
  }
}

.mapboxgl-ctrl-top-right
{
  top: 140px !important;
}

.about
{
  color: #ffffff;
}
